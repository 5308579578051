import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent, CardActions, Typography, Grid, Divider, Chip, Tooltip, TextField, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import StarIcon from '@material-ui/icons/Star';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import LockIcon from '@material-ui/icons/Lock';
import HistoryIcon from '@material-ui/icons/History'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import SpeedIcon from '@material-ui/icons/Speed';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { useState } from 'react';
import strings from '../../../localization';
import { useConnection } from '../../../utils/connection';
import { useWallet } from '../../../utils/wallet';
import { useTokenAccounts } from '../../../utils/tokens/hooks';
import { USDT_PROGRAM_ID } from '../../../utils/tokens/instructions';
import LoadingIndicator from '../../LoadingIndicator';
import MinerOrderPage from './MinerOrderPage';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import UsdtDepositDialog from '../../bridge/usdt/UsdtDepositDialog';
import UsdtExchangeDialog from '../../bridge/usdt/UsdtExchangeDialog';
import { CartIcon, CartDialog, CartProvider, useCart } from './CartContext';
import OrderHistoryDialog from './OrderHistory';
import { NoSsr } from '@mui/material'; 


export default function MinerStakingDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();
  const wallet = useWallet();
  const connection = useConnection();
  const [selectedMinerId, setSelectedMinerId] = useState(null);
  const [showOrderPage, setShowOrderPage] = useState(false);
  const { data: usdtTokenAccounts, isLoading: loadingUsdtAccounts } = useTokenAccounts(USDT_PROGRAM_ID);

  const [usdtDepositDialogOpen, setUsdtDepositDialogOpen] = useState(false);
  const [usdtExchangeDialogOpen, setUsdtExchangeDialogOpen] = useState(false);
  const [cartDialogOpen, setCartDialogOpen] = useState(false);
  const [orderHistoryDialogOpen, setOrderHistoryDialogOpen] = useState(false);
  const [quantities, setQuantities] = useState({});
  const { addToCart, getTotalItems } = useCart();

  // Calculate total USDT balance
  const totalUsdtBalance = usdtTokenAccounts?.reduce((acc, curr) => {
    const tokenAmount = curr.account.data.parsed.info.tokenAmount;
    return acc + tokenAmount.uiAmount;
  }, 0) ?? 0;

  const handleProceedToCheckout = () => {
    setCartDialogOpen(false);
    setShowOrderPage(true);
  };

  const handleOrderClose = () => {
    setShowOrderPage(false);
  };
  
  const handleAddToCart = (miner) => {
    const quantity = quantities[miner.id] || 1;
    addToCart(miner, quantity);
    // Reset quantity after adding to cart
    setQuantities({
      ...quantities,
      [miner.id]: 1
    });
  };
  
  const handleQuantityChange = (minerId, newValue) => {
    const newQuantity = parseInt(newValue, 10);
    if (newQuantity > 0 || newValue === '') {
      setQuantities({
        ...quantities,
        [minerId]: newValue === '' ? '' : newQuantity
      });
    }
  };
  
  const handleIncrement = (minerId) => {
    const currentQty = quantities[minerId] || 1;
    setQuantities({
      ...quantities,
      [minerId]: currentQty + 1
    });
  };
  
  const handleDecrement = (minerId) => {
    const currentQty = quantities[minerId] || 1;
    if (currentQty > 1) {
      setQuantities({
        ...quantities,
        [minerId]: currentQty - 1
      });
    }
  };

  const handleBuyMiner = (minerId) => {
    setSelectedMinerId(minerId);
    setShowOrderPage(true);
  };
  if (showOrderPage) {
    return (
      <MinerOrderPage
        open={open}
        onClose={onClose}
        selectedMinerId={selectedMinerId}
      />
    );
  }

  const formatValue = (value) => {
    return typeof value === 'number' ? value.toLocaleString() : value;
  };

  return (
    <>
      <DialogTitle>
        <Box className={classes.titleContainer}>
          <Typography variant="h6">{strings.minerStaking || "DOMI AI Node Machines"}</Typography>
          <Box display="flex" alignItems="center">
            <Box className={classes.balanceContainer}>
              {loadingUsdtAccounts ? (
                <Typography variant="h6" className={classes.balanceText}>
                  Loading...
                </Typography>
              ) : (
                <Typography variant="h6" className={classes.balanceText}>
                  {totalUsdtBalance.toFixed(2)} USDT
                </Typography>
              )}
            </Box>
            <HistoryIcon onClick={() => setOrderHistoryDialogOpen(true)}>
            </HistoryIcon>
            <CartIcon onClick={() => setCartDialogOpen(true)} />
          </Box>
        </Box>
      </DialogTitle>
      
      <Box display="flex" justifyContent="left" mt={1} mb={1} sx={{ pl: 2 }}>
        <Box display="flex" gap={2}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setUsdtDepositDialogOpen(true)}
            className={classes.actionButton}
          >
            Deposit USDT
          </Button>
          
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setUsdtExchangeDialogOpen(true)}
            className={classes.actionButton}
          >
            Buy DOMI
          </Button>
        </Box>
      </Box>
      
      <DialogContent>
        <Typography variant="body1" color="textSecondary" paragraph>
          {strings.minerStakingDescription}
        </Typography>
        
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {MINER_TYPES.map((miner) => (
            <Grid item xs={12} md={4} key={miner.id}>
              <Card 
                className={`${classes.minerCard} ${miner.id === 2 ? classes.popularMiner : ''}`} 
                variant="outlined"
              >
                {miner.highlight && (
                  <Chip 
                    label={miner.highlight} 
                    color={miner.id === 2 ? "primary" : "default"}
                    className={classes.highlightChip} 
                  />
                )}
                <CardContent className={classes.minerCardContent}>
                  <Typography className={classes.minerName} color="textPrimary" gutterBottom>
                    <StarIcon style={{ marginRight: 8 }} /> Tier: {miner.name}
                  </Typography>
                  
                  <Typography className={classes.minerPrice} color={'black'}>
                    {miner.price} USDT
                  </Typography>
                  
                  <div className={classes.propertyRow}>
                    <MoneyIcon className={classes.propertyIcon} />
                    <Typography className={classes.propertyLabel} color={'black'}>
                      {strings.totalReward}
                    </Typography>
                    <Typography className={classes.propertyValue}>
                      {formatValue(miner.totalReward)} DOMI
                      {miner.lockedReward && (
                        <span> ({formatValue(miner.lockedReward)} locked for {miner.lockedRewardDays} days)</span>
                      )}
                    </Typography>
                  </div>
                  
                  <div className={classes.propertyRow}>
                    <CalendarTodayIcon className={classes.propertyIcon} />
                    <Typography className={classes.propertyLabel} color={'black'}>
                      {strings.period}
                    </Typography>
                    <Typography className={classes.propertyValue} color={'black'}>
                      {miner.lockupPeriod} days
                    </Typography>
                  </div>
                  
                  <div className={classes.propertyRow}>
                    <SpeedIcon className={classes.propertyIcon} />
                    <Typography className={classes.propertyLabel} color={'black'}>
                      {strings.dailyReward}
                    </Typography>
                    <Typography className={classes.propertyValue}>
                      {miner.dailyReward.toFixed(2)} DOMI
                    </Typography>
                  </div>
                  
                  <div className={classes.propertyRow}>
                    <LockIcon className={classes.propertyIcon} />
                    <Typography className={classes.propertyLabel} color={'black'}>
                      {strings.reqLock}
                    </Typography>
                    <Typography className={classes.propertyValue}>
                      {miner.requiresLockup ? 
                        `${formatValue(miner.lockupAmount)} DOMI for ${miner.lockupDays} days` : 
                        "No"}
                    </Typography>
                  </div>
                  
                  <div className={classes.propertyRow}>
                    <RemoveCircleIcon className={classes.propertyIcon} />
                    <Typography className={classes.propertyLabel} color={'black'}>
                      {strings.minStake}
                    </Typography>
                    <Typography className={classes.propertyValue}>
                      {miner.minStake === 0 ? 
                        "None" : 
                        `${formatValue(miner.minStake)} (locked for ${miner.minStakeLockup} days)`}
                    </Typography>
                  </div>
                  
                  <div className={classes.propertyRow}>
                    <AddCircleIcon className={classes.propertyIcon} />
                    <Typography className={classes.propertyLabel} color={'black'}>
                    {strings.maxStake}
                    </Typography>
                    <Typography className={classes.propertyValue}>
                      {formatValue(miner.maxStake)} (locked for ${miner.maxStakeLockup} days)
                    </Typography>
                  </div>
                  
                  <Divider className={classes.detailDivider} />
                  
                  <Typography variant="body2" color="textSecondary">
                    {miner.description.en}
                  </Typography>
                </CardContent>
                
                <CardActions className={classes.minerCardActions}>
                  <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', px: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton 
                        size="small" 
                        onClick={() => handleDecrement(miner.id)}
                        disabled={(quantities[miner.id] || 1) <= 1}
                      >
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                      
                      <TextField
                        value={quantities[miner.id] || 1}
                        onChange={(e) => handleQuantityChange(miner.id, e.target.value)}
                        inputProps={{ 
                          min: 1, 
                          style: { textAlign: 'center', width: '40px' } 
                        }}
                        variant="outlined"
                        size="small"
                        sx={{ mx: 1 }}
                      />
                      
                      <IconButton 
                        size="small" 
                        onClick={() => handleIncrement(miner.id)}
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                    </Box>
                    
                    <Button 
                      variant="contained"
                      style={{ 
                        backgroundColor: (loadingUsdtAccounts || totalUsdtBalance < miner.price) ? 
                                        'rgba(255, 153, 0, 0.5)' : // Grayed out orange
                                        '#FF9900', // Bright orange
                        color: '#000000',
                      }}
                      className={classes.addToCartButton}
                      onClick={() => handleAddToCart(miner)}
                    >
                      {strings.addToCart || "Add to Cart"}
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          {strings.close}
        </Button>
      </DialogActions>
      
      <UsdtDepositDialog
        open={usdtDepositDialogOpen}
        onClose={() => setUsdtDepositDialogOpen(false)}
      />

      <UsdtExchangeDialog
        open={usdtExchangeDialogOpen}
        onClose={() => setUsdtExchangeDialogOpen(false)}
      />
      
      <CartDialog
        open={cartDialogOpen}
        onClose={() => setCartDialogOpen(false)}
        onProceedToCheckout={handleProceedToCheckout}
      />
      <NoSsr>
        <OrderHistoryDialog
          open={orderHistoryDialogOpen}
          onClose={() => setOrderHistoryDialogOpen(false)}
        />
      </NoSsr>
    </>
  );
}

// Miner types data
const MINER_TYPES = [
  {
    id: 1,
    name: 'M1',
    price: 299,
    totalReward: 1000,
    lockupPeriod: 180,
    dailyReward: 5.56,
    requiresLockup: true,
    lockupAmount: 1000,
    lockupDays: 180,
    minStake: 5,
    minStakeLockup: 30,
    maxStake: 3000,
    maxStakeLockup: 30,
    highlight: 'Entry Level',
    description: {
      en: 'Stake 1000 DOMI locked for 180 days, earn 1000 DOMI in rewards',
      zh: '质押1000个多米，180天挖1000个多米。加上1000个释放的多米，一共就是2000个多米。',
    }
  },
  {
    id: 2,
    name: 'M2',
    price: 899,
    totalReward: 3600,
    lockedReward: 1000,
    lockedRewardDays: 60,
    lockupPeriod: 60,
    dailyReward: 43.33,
    requiresLockup: false,
    minStake: 0,
    maxStake: 5000,
    maxStakeLockup: 30,
    highlight: 'Most Popular',
    description: {
      en: 'No lockup required. Earn 3600 DOMI in 60 days with 1000 DOMI locked for 60 days!',
      zh: '60天挖出3600个币，就回本了。然后每天产币3.55个，就是净赚的。',
    }
  },
  {
    id: 3,
    name: 'M3',
    price: 1499,
    totalReward: 6000,
    lockedReward: 1000,
    lockedRewardDays: 60,
    lockupPeriod: 15,
    dailyReward: 333.33,
    requiresLockup: false,
    minStake: 0,
    maxStake: 8000,
    maxStakeLockup: 30,
    highlight: 'Best Value',
    description: {
      en: 'No lockup required. Earn 6000 DOMI in just 15 days with 1000 DOMI locked for 60 days!',
      zh: '15天挖出6000个币，就回本了。然后每天产币6个，就是净赚的。',
    }
  }
];

const useStyles = makeStyles((theme) => ({
  minerCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: theme.shadows[4],
    },
  },
  popularMiner: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  minerCardContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  minerCardActions: {
    padding: theme.spacing(2),
    justifyContent: 'center',
  },
  minerName: {
    fontSize: 24,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  minerPrice: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    fontSize: 28,
  },
  propertyRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
  },
  propertyIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  propertyLabel: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  propertyValue: {
    fontWeight: 'bold',
    marginLeft: 'auto',
  },
  detailDivider: {
    margin: theme.spacing(2, 0),
  },
  buyButton: {
    padding: theme.spacing(1.5),
    fontWeight: 'bold',
    fontSize: 16,
    color: '#000000',
    '&:disabled': {
      color: '#000000',
      opacity: 0.7,
    }
  },
  highlightChip: {
    position: 'absolute',
    top: 16,
    right: 16,
    fontWeight: 'bold',
  },
  roiHighlight: {
    backgroundColor: theme.palette.success.light,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 400,
  },
  balanceText: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  balanceContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  actionButton: {
    minWidth: '120px',
    fontWeight: 'bold',
  }
}));
