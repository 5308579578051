import { DialogContentText, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Decimal } from 'decimal.js';
import { useSnackbar } from 'notistack';
import { useState, useMemo, useEffect } from 'react';
import strings from '../../../localization';
import { useConnection } from '../../../utils/connection';
import { AIRDROP_BACKEND_URL } from '../../../utils/env-variables';
import { signAndSendTransaction, transferTokens } from '../../../utils/tokens';
import { TokenAccount, useTokenAccounts } from '../../../utils/tokens/hooks';
import { USDT_PROGRAM_ID } from '../../../utils/tokens/instructions';
import { estimateTransactionSize } from '../../../utils/transactions';
import { Wallet, useWallet } from '../../../utils/wallet';
import DialogForm from '../../DialogForm';

const MINIMUM_USDT_EXCHANGE_AMOUNT = 1.2;

export default function UsdtExchangeDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const wallet = useWallet();
  const connection = useConnection();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { data: tokenAccounts } = useTokenAccounts(USDT_PROGRAM_ID);

  const availableAmount = (tokenAccounts ?? []).reduce((acc, curr) => {
    const tokenAmount = curr.account.data.parsed.info.tokenAmount;
    return acc + tokenAmount.uiAmount;
  }, 0);

  const [inputAmount, setInputAmount] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);

  const parsedInputAmount = /^\d+\.?\d*$/.test(inputAmount)
    ? new Decimal(inputAmount)
    : new Decimal(0);

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      if (!tokenAccounts || !priceData) {
        return;
      }

      const id = enqueueSnackbar('Sending transactions...', {
        variant: 'info',
        persist: true,
      });

      try {
        const signatures = await sendTransferTransactions(
          wallet,
          connection,
          tokenAccounts,
          parsedInputAmount,
        );

        // Send both signatures and exchange rate info to backend
        const response = await fetch(`https://airdrop2.domichain.io/v1/exchange/order`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userWalletAddress: wallet.publicKey.toBase58(), 
            signatures,
            exchangeData: {
              usdtAmount: parsedInputAmount.toString(),
              exchangeRate: priceData,
              expectedDomi: estimatedDomi,
              timestamp: Date.now()
            }
          }),
        });

        if (!response.ok) {
          throw new Error('Exchange failed');
        }

        closeSnackbar(id);
        enqueueSnackbar('USDT sent successfully. Your DOMI will arrive shortly.', {
          variant: 'success',
          autoHideDuration: 5000,
        });
        
        onClose();
        
      } catch (err) {
        if (err instanceof Error) {
          closeSnackbar(id);
          enqueueSnackbar(err.message, { variant: 'error' });
          console.error(err);
        }
      }
    },
  });

  // Price fetching
  const { data: priceData, isLoading: isPriceLoading } = useQuery({
    queryKey: ['mexc-price'],
    queryFn: async () => {
      const response = await fetch(`https://airdrop2.domichain.io/v1/exchange/price`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
  
      // console.log('Frontend: Response status:', response.status);
      
      if (!response.ok) {
        console.error('Frontend: Failed to fetch price, status:', response.status);
        throw new Error('Failed to fetch DOMI price');
      }
  
      const data = await response.json();
      // console.log('Frontend: Received price data:', data);
  
      // The API returns { status: 'success', data: { success: true, price: number } }
      if (data.status !== 'success' || !data.data.success) {
        console.error('Frontend: Price fetch unsuccessful:', data.error);
        throw new Error(data.error || 'Failed to fetch price');
      }
  
      return data.data.price; 
    },
    refetchInterval: 10000,
    onError: (error) => {
      console.error('Frontend: Query error:', error);
    }
  });

  const estimatedDomi = useMemo(() => {
    if (!priceData || !parsedInputAmount) return null;
    return parsedInputAmount.dividedBy(priceData).toFixed(4);
  }, [priceData, parsedInputAmount]);

  const validAmount = useMemo(() => {
    if (!parsedInputAmount || !priceData) return false;
    
    return parsedInputAmount.greaterThanOrEqualTo(MINIMUM_USDT_EXCHANGE_AMOUNT) && 
           parsedInputAmount.lessThanOrEqualTo(availableAmount);
  }, [parsedInputAmount, availableAmount, priceData]);

  const isDisabled = !validAmount || isLoading;

  const amountError = useMemo(() => {
    if (!parsedInputAmount) return '';
    if (parsedInputAmount.lessThan(MINIMUM_USDT_EXCHANGE_AMOUNT)) {
      return `Minimum amount is ${MINIMUM_USDT_EXCHANGE_AMOUNT} USDT`;
    }
    if (parsedInputAmount.greaterThan(availableAmount)) {
      return 'Insufficient balance';
    }
    return '';
  }, [parsedInputAmount, availableAmount]);

  return (
    <>
      <DialogForm
        open={open}
        onClose={onClose}
        fullWidth
      >
        <DialogTitle>{strings.buyDomiDialogTitle}</DialogTitle>
        <DialogContent>
          {isPriceLoading ? (
            <DialogContentText>
              Loading current exchange rate...
            </DialogContentText>
          ) : (
            <DialogContentText>
              Current Rate: 1 DOMI = {priceData?.toFixed(4)} USDT
            </DialogContentText>
          )}

          <TextField
            label={strings.amount}
            fullWidth
            variant="outlined"
            margin="normal"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={() => setInputAmount(availableAmount.toFixed(9))}
                  >
                    {strings.max}
                  </Button>
                  <Typography color="primary">USDT</Typography>
                </InputAdornment>
              ),
              inputProps: {
                step: 10 ** -9,
              },
            }}
            value={inputAmount}
            onChange={(e) => setInputAmount(e.target.value.trim())}
            error={!!amountError}
            helperText={
              amountError || (
                <span onClick={() => setInputAmount(availableAmount.toFixed(9))}>
                  {strings.max}: {availableAmount.toFixed(9)}
                </span>
              )
            }
          />

          {estimatedDomi && (
            <DialogContentText>
              You will receive approximately {estimatedDomi} DOMI
            </DialogContentText>
          )}
        </DialogContent>

        <DialogActions
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <Button onClick={onClose}>{strings.cancel}</Button>
            <Button 
              color="primary" 
              disabled={isDisabled || isPriceLoading || !estimatedDomi || !validAmount}
              onClick={() => setShowConfirm(true)}
            >
              {strings.buyButton}
            </Button>
          </div>
        </DialogActions>
      </DialogForm>

      <ConfirmExchangeDialog
              open={showConfirm}
              onClose={() => setShowConfirm(false)}
              usdtAmount={inputAmount}
              estimatedDomi={estimatedDomi || '0'}
              exchangeRate={priceData || 0}
              onConfirm={() => {
                setShowConfirm(false);
                mutate();
              }} 
            />
    </>
  );
}

async function getTransferDestination() {
  const response = await fetch(
    `${AIRDROP_BACKEND_URL}/v1/get-exchange-address`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to fetch exchange destination address');
  }

  const responseJson = await response.json();
  const destinationPublicKey = responseJson.data?.publicKey;

  if (!destinationPublicKey) {
    throw new Error('Failed to get exchange destination address');
  }
  return new PublicKey(destinationPublicKey);
}

async function sendTransferTransactions(
  wallet: Wallet,
  connection: Connection,
  tokenAccounts: TokenAccount[],
  amount: Decimal,
) {
  const signatures: Array<string> = [];
  const destinationPublicKey = new PublicKey('DWXcudFyubtXZboMtiz58hDCRSwAvhi4MTpUe1svxmR8');

  let transaction = new Transaction();

  for (const { account, transferAmount } of findSuitableAccountsForTransfer(
    tokenAccounts,
    amount,
  )) {
    const transferTransaction = await transferTokens({
      connection,
      owner: wallet,
      sourcePublicKey: account.pubkey,
      destinationPublicKey,
      amount: BigInt(
        transferAmount
          .mul(10 ** account.account.data.parsed.info.tokenAmount.decimals)
          .toString(),
      ),
      mint: new PublicKey(account.account.data.parsed.info.mint),
      decimals: account.account.data.parsed.info.tokenAmount.decimals,
      overrideDestinationCheck: true,
      programId: USDT_PROGRAM_ID,
      memo: null,
    });

    const estimatedTransactionSize = estimateTransactionSize(
      new Transaction().add(transaction).add(transferTransaction),
      wallet.publicKey,
    );

    if (estimatedTransactionSize < 1232) {
      transaction.add(transferTransaction);
      continue;
    }

    // Send transaction and get signature
    const signature = await signAndSendTransaction(connection, transaction, wallet, []);
    
    // Wait for confirmation before proceeding
    console.log('Waiting for transaction confirmation:', signature);
    await connection.confirmTransaction(signature, 'confirmed');
    console.log('Transaction confirmed:', signature);
    
    signatures.push(signature);
    transaction = transferTransaction;
  }

  // Process final transaction
  const finalSignature = await signAndSendTransaction(connection, transaction, wallet, []);
  
  // Wait for final transaction confirmation
  console.log('Waiting for final transaction confirmation:', finalSignature);
  await connection.confirmTransaction(finalSignature, 'confirmed');
  console.log('Final transaction confirmed:', finalSignature);
  
  signatures.push(finalSignature);
  
  return signatures;
}

function findSuitableAccountsForTransfer(
  accounts: TokenAccount[],
  transferAmount: Decimal,
) {
  const result: Array<{ account: TokenAccount; transferAmount: Decimal }> = [];
  const sortedAccounts = accounts
    .slice()
    .sort((a, b) =>
      Number(
        b.account.data.parsed.info.tokenAmount.uiAmount -
          a.account.data.parsed.info.tokenAmount.uiAmount,
      ),
    );

  let remainingTransferAmount = transferAmount;

  for (const account of sortedAccounts) {
    if (remainingTransferAmount.lessThanOrEqualTo(0)) {
      break;
    }
    const maxTransfer = Decimal.min(
      account.account.data.parsed.info.tokenAmount.uiAmountString,
      remainingTransferAmount,
    );
    result.push({ account, transferAmount: maxTransfer });
    remainingTransferAmount = remainingTransferAmount.sub(maxTransfer);
  }

  if (remainingTransferAmount.greaterThan(0.1)) {
    throw new Error('Insufficient funds');
  }

  return result;
}

function ConfirmExchangeDialog({ 
  open, 
  onClose, 
  usdtAmount, 
  estimatedDomi, 
  exchangeRate, 
  onConfirm 
}: { 
  open: boolean;
  onClose: () => void;
  usdtAmount: string;
  estimatedDomi: string;
  exchangeRate: number;
  onConfirm: () => void;
}) {
  return (
    <DialogForm open={open} onClose={onClose} fullWidth>
      <DialogTitle>Confirm Exchange</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to exchange:
        </DialogContentText>
        <Typography variant="h6" gutterBottom>
          {usdtAmount} USDT for approximately {estimatedDomi} DOMI
        </Typography>
        <DialogContentText>
          Exchange Rate: 1 DOMI = {exchangeRate} USDT
        </DialogContentText>
        <DialogContentText color="textSecondary">
          The exact amount of DOMI may vary slightly based on market conditions when the exchange is executed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} color="primary">
          Confirm Exchange
        </Button>
      </DialogActions>
    </DialogForm>
  );
}