import {
  Authorized,
  Connection, CreateStakeAccountWithSeedParams,
  LAMPORTS_PER_SOL, Lockup,
  PublicKey,
  RpcResponseAndContext, SignatureResult,
  StakeProgram,
} from '@solana/web3.js';
import { StakingPlan } from '../components/staking/surfaces/ActivateStake';
import { signAndSendTransaction } from '../utils/tokens';
import { formatLamports } from '../utils/utils';
import { Wallet } from '../utils/wallet';

export type StakingMode = 'classic' | 'new'

export default class StakingService {
  private readonly wallet: Wallet;
  private readonly connection: Connection;
  private readonly stakingBackendUrl: string;
  private readonly mode: StakingMode;

  constructor(wallet: Wallet, connection: Connection, stakingBackendUrl: string,
              mode: StakingMode) {
    this.wallet = wallet
    this.connection = connection
    this.stakingBackendUrl = stakingBackendUrl
    this.mode = mode
  }

  async getStakeAccount(): Promise<PublicKey> {
    const seed = this.getStakeAccountSeed();
    return this.wallet.getStakeAccount(seed);
  }

  getStakeAccountSeed(): string {
    switch (this.mode) {
      case 'classic':
        return 'stake';
      case 'new':
        return 'stake_new'
      default:
        throw new Error(`Unknown staking mode: ${this.mode}`);
    }
  }

  async delegateStake(stakeAccount: PublicKey, tokenAmount: string,
                      stakingLimit: number, votePubkey: PublicKey,
                      lockupPeriodSeconds?: number | undefined
  ): Promise<string> {
    const stakeMinimumDelegation = await this.connection.getStakeMinimumDelegation();
    const lamports = Math.round(parseFloat(tokenAmount) * LAMPORTS_PER_SOL);

    if (!lamports || lamports < stakeMinimumDelegation.value) {
      throw new Error(
        `Stake amount should be more than ${formatLamports(
          stakeMinimumDelegation.value,
        )} DOMI`,
      );
    }

    if (stakingLimit > 0 && lamports > stakingLimit * LAMPORTS_PER_SOL) {
      throw new Error(`Stake amount should not exceed ${stakingLimit} DOMI`);
    }

    {
      const seed = this.getStakeAccountSeed();

      const result = await this._createStakingAccount(stakeAccount, seed,
        lamports, lockupPeriodSeconds);

      if (result.value.err) {
        throw new Error(
          `Failed to confirm create account transaction: ${result.value.err}`,
        );
      }
    }

    return await this._delegateStake(stakeAccount, votePubkey);
  }

  async deactivateStake(stakeAccount: PublicKey): Promise<string> {
    const transaction = StakeProgram.deactivate({
      stakePubkey: stakeAccount,
      authorizedPubkey: this.wallet.publicKey,
    });

    const { blockhash, lastValidBlockHeight } =
      await this.connection.getLatestBlockhash();

    transaction.feePayer = this.wallet.publicKey;
    transaction.recentBlockhash = blockhash;
    transaction.lastValidBlockHeight = lastValidBlockHeight;

    return await signAndSendTransaction(this.connection, transaction, this.wallet, []);
  }

  async withdrawStake(stakeAccount: PublicKey): Promise<string> {
    const transaction = StakeProgram.withdraw({
      stakePubkey: stakeAccount,
      authorizedPubkey: this.wallet.publicKey,
      toPubkey: this.wallet.publicKey,
      lamports: await this.connection.getBalance(stakeAccount),
    });

    const { blockhash, lastValidBlockHeight } =
      await this.connection.getLatestBlockhash();

    transaction.feePayer = this.wallet.publicKey;
    transaction.recentBlockhash = blockhash;
    transaction.lastValidBlockHeight = lastValidBlockHeight;

    return await signAndSendTransaction(this.connection, transaction, this.wallet, []);
  };

  async registerStake(stakeAccount: PublicKey): Promise<any | null> {
    const stakeAccountInfo = await this.connection.getStakeActivation(
      stakeAccount,
    );
    const stakeAmount = stakeAccountInfo.active + stakeAccountInfo.inactive;

    if (this.mode == 'classic') {
      return { stakeAmount };
    }

    try {
      const message = Buffer.from('domichain-staking');
      const signature = await this.wallet.createSignature(message);

      const response = await fetch(
        `${this.stakingBackendUrl}/v1/stakes/${stakeAccount.toBase58()}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ownerAddress: this.wallet.publicKey.toBase58(),
            stakeAmount,
            signature,
          }),
        },
      );
      const { data } = await response.json()
      return data;
    } catch (error) {
      console.error('Failed to save initial stake in the backend', error);
    }
    return null;
  }

  async deregisterStake(stakeAccount: PublicKey): Promise<any | null> {
    try {
      if (this.mode == 'classic') {
        return null;
      }

      const message = Buffer.from('domichain-staking');
      const signature = await this.wallet.createSignature(message);

      const searchParams = new URLSearchParams();
      searchParams.append('ownerAddress', this.wallet.publicKey.toBase58());
      searchParams.append('signature', signature);

      const response = await fetch(`${this.stakingBackendUrl}/v2/stakes/${stakeAccount.toBase58()}`, {
        method: 'DELETE',
        body: searchParams,
      });
      const { data } = await response.json()
      return data;
    } catch (error) {
      console.warn(`Failed to delete active stake info: `, error);
    }
    return null;
  }

  async getStakingRewards(stakeAccount: PublicKey): Promise<any | null> {
    if (this.mode == 'classic') {
      return null;
    }
  
    try {
      // Try new rewards first
      const response = await fetch(
        `${this.stakingBackendUrl}/v2/stakes/rewards/${stakeAccount.toBase58()}`,
      );
  
      if (!response.ok) {
        // If new rewards fail, try deprecated rewards
        const depResponse = await fetch(
          `${this.stakingBackendUrl}/v2/stakes/dep-rewards/${stakeAccount.toBase58()}`,
        );
        
        if (!depResponse.ok) {
          console.warn('Both new and deprecated reward endpoints failed');
          return null;
        }
  
        const { data } = await depResponse.json();
        return data;
      }
  
      const { data } = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch staking rewards:', error);
      return null;
    }
  }

  async claimRewards(stakeAccount: PublicKey): Promise<any | null> {
    if (this.mode == 'classic') {
      return null;
    }
  
    const message = Buffer.from('domichain-staking');
    const signature = await this.wallet.createSignature(message);
    const body = JSON.stringify({
      ownerAddress: this.wallet.publicKey.toBase58(),
      signature,
    });
  
    try {
      // Try new rewards first
      const response = await fetch(
        `${this.stakingBackendUrl}/v2/stakes/rewards/${stakeAccount.toBase58()}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body,
        },
      );
  
      if (!response.ok) {
        // If new rewards fail, try deprecated rewards
        const depResponse = await fetch(
          `${this.stakingBackendUrl}/v2/stakes/dep-rewards/${stakeAccount.toBase58()}`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body,
          },
        );
  
        if (!depResponse.ok) {
          console.warn('Both new and deprecated reward endpoints failed');
          return null;
        }
  
        const { data } = await depResponse.json();
        return data;
      }
  
      const { data } = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to claim rewards:', error);
      return null;
    }
  }
  

  async fetchActiveStakeInfo(stakeAccount: PublicKey): Promise<any | null> {
    if (this.mode == 'classic') {
      return null;
    }

    const response = await fetch(
      `${this.stakingBackendUrl}/v2/stakes/${stakeAccount.toBase58()}`,
    );
    const { data } = await response.json();
    return data;
  }

  async fetchDepositAddress(stakingPlanId: number): Promise<string> {
    const searchParams = new URLSearchParams()
    searchParams.append('stakingPlanId', stakingPlanId.toString());
    searchParams.append('domichainAddress', this.wallet.publicKey?.toBase58());

    const response = await fetch(`${this.stakingBackendUrl}/v1/deposit`, {
      body: searchParams,
    });
    if (!response.ok) {
      throw new Error('No deposit address');
    }

    const { data } = await response.json();
    return data.depositAddress;
  }

  async fetchAssignedValidatorInfo(): Promise<any> {
    const searchParams = new URLSearchParams()
    searchParams.append('domichainAddress', this.wallet.publicKey?.toBase58());

    const response = await fetch(`${this.stakingBackendUrl}/v2/validator`, {
      body: searchParams,
    });
    if (!response.ok) {
      throw new Error('No assigned validator yet');
    }

    const { data } = await response.json();
    return data;
  }

  async fetchStakingPlans(): Promise<StakingPlan[]> {
    const response = await fetch(`${this.stakingBackendUrl}/v2/plans`);
    const data = await response.json();
    return data.data.plans as StakingPlan[];
  }

  async fetchNewStakePlan(): Promise<StakingPlan> {
    const response = await fetch(`${this.stakingBackendUrl}/v2/new-stake/plan`);
    const data = await response.json();
    return data.data as StakingPlan;
  }

  async _createStakingAccount(stakeAccount: PublicKey, stakeAccountSeed: string,
                              lamports: number,
                              lockupPeriodSeconds?: number | undefined
  ): Promise<RpcResponseAndContext<SignatureResult>> {
    const createAccountParams: CreateStakeAccountWithSeedParams = {
      fromPubkey: this.wallet.publicKey,
      basePubkey: this.wallet.publicKey,
      stakePubkey: stakeAccount,
      seed: stakeAccountSeed,
      authorized: new Authorized(this.wallet.publicKey, this.wallet.publicKey),
      lamports: lamports,
    }
    if (lockupPeriodSeconds) {
      const lockupTimestamp = Math.floor(Date.now()/1000) + lockupPeriodSeconds;
      createAccountParams.lockup = new Lockup(lockupTimestamp, 0, PublicKey.default)
    }

    const { blockhash, lastValidBlockHeight } =
      await this.connection.getLatestBlockhash();

    const transaction = StakeProgram.createAccountWithSeed(createAccountParams);
    transaction.feePayer = this.wallet.publicKey;
    transaction.recentBlockhash = blockhash;
    transaction.lastValidBlockHeight = lastValidBlockHeight;

    const signature = await signAndSendTransaction(
      this.connection,
      transaction,
      this.wallet,
      [],
    );

    return await this.connection.confirmTransaction(
      { signature, blockhash, lastValidBlockHeight },
      'finalized',
    );
  }

  async _delegateStake(stakeAccount: PublicKey, votePubkey: PublicKey): Promise<string> {
    const transaction = StakeProgram.delegate({
      stakePubkey: stakeAccount,
      authorizedPubkey: this.wallet.publicKey,
      votePubkey: votePubkey,
    });

    const { blockhash, lastValidBlockHeight } =
      await this.connection.getLatestBlockhash();

    transaction.feePayer = this.wallet.publicKey;
    transaction.recentBlockhash = blockhash;
    transaction.lastValidBlockHeight = lastValidBlockHeight;

    const signature = await signAndSendTransaction(this.connection, transaction, this.wallet, []);

    const result = await this.connection.confirmTransaction(
      { signature, blockhash, lastValidBlockHeight },
      'finalized',
    );

    if (result.value.err) {
      throw new Error(
        `Failed to confirm delegate stake transaction: ${result.value.err}`,
      );
    }
    return signature;
  }
}
