import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const APP_VERSION = '1.6.0';

const useStyles = makeStyles((theme) => ({
  versionText: {
    color: theme.palette.text.secondary,
    fontSize: '0.7rem',
    opacity: 0.6,
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const getAppVersion = () => {
    return APP_VERSION;
};

const VersionDisplay = () => {
  const classes = useStyles();
  return (
    <Typography className={classes.versionText}>
      v{APP_VERSION}
    </Typography>
  );
};

export default VersionDisplay;