import React, { useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import QrcodeIcon from 'mdi-material-ui/Qrcode';
import { createPortal } from 'react-dom';
import { Close } from '@material-ui/icons';
import { decodeAccount } from '../utils/utils';
import DialogForm from './DialogForm';
import strings from '../localization';

export default function AddAccountDialog({ open, onAdd, onClose }) {
  const [name, setName] = useState('');
  const [isImport, setIsImport] = useState(false);
  const [importedPrivateKey, setPrivateKey] = useState('');
  const [scannerStatus, setScannerStatus] = useState();
  const [scannerIsVisible, setScannerIsVisible] = useState(false);

  const importedAccount = isImport
    ? decodeAccount(importedPrivateKey)
    : undefined;
  const isAddEnabled = isImport ? name && importedAccount !== undefined : name;

  const presentQrScanner = () => {
    window.QRScanner.prepare((_, status) => {
      setScannerStatus(status);
      setScannerIsVisible(true);
    });
  };

  React.useEffect(() => {
    if (scannerIsVisible) {
      if (scannerStatus?.denied) {
        window.QRScanner.openSettings();
        return;
      }

      if (!scannerStatus?.authorized) {
        return;
      }

      const handleScan = (err, text) => {
        if (err) {
          setScannerIsVisible(false);
          return;
        }

        if (text) {
          setPrivateKey(text);
          setScannerIsVisible(false);
        }
      };

      const dialogElements = document.querySelectorAll('[role="presentation"]');
      const rootElement = document.getElementById('root');

      window.QRScanner.scan(handleScan);
      window.QRScanner.show(() => {
        dialogElements.forEach((el) => (el.style.display = 'none'));
        rootElement.style.display = 'none';
      });

      return () => {
        window.QRScanner.cancelScan();
        window.QRScanner.hide(() => {
          dialogElements.forEach((el) => (el.style.display = ''));
          rootElement.style.display = '';
        });
      };
    }
  }, [scannerStatus, scannerIsVisible]);

  return (
    <DialogForm
      open={open}
      onEnter={() => {
        setName('');
        setIsImport(false);
        setPrivateKey('');
      }}
      onClose={onClose}
      onSubmit={() => onAdd({ name, importedAccount })}
      fullWidth
    >
      <DialogTitle>{strings.addAccount}</DialogTitle>
      <DialogContent style={{ paddingTop: 16 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextField
            label={strings.name}
            fullWidth
            variant="outlined"
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value.trim())}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isImport}
                  onChange={() => setIsImport(!isImport)}
                />
              }
              label={strings.importPrivateKey}
            />
          </FormGroup>
          {isImport && (
            <TextField
              label={strings.pastePrivateKey}
              fullWidth
              type="password"
              value={importedPrivateKey}
              variant="outlined"
              margin="normal"
              onChange={(e) => setPrivateKey(e.target.value.trim())}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton color="inherit" onClick={presentQrScanner}>
                      <QrcodeIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{strings.close}</Button>
        <Button type="submit" color="primary" disabled={!isAddEnabled}>
          {strings.add}
        </Button>
      </DialogActions>
      
      {scannerIsVisible &&
        createPortal(
          <div
            style={{
              margin: 8,
              paddingTop: 'env(safe-area-inset-top)',
            }}
          >
            <IconButton
              color="inherit"
              onClick={() => setScannerIsVisible(false)}
            >
              <Close />
            </IconButton>
          </div>,
          document.body,
        )}
    </DialogForm>
  );
}