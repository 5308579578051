import {
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import strings from '../../../localization';
import StakingService, { StakingMode } from '../../../services/stakingService';
import { useConnection } from '../../../utils/connection';
import { useWallet } from '../../../utils/wallet';
import LoadingIndicator from '../../LoadingIndicator';
import { DelegateStake } from './DelegateStake';
import DepositInfo from './DepositInfo';
import StakingSettingsDialog from '../StakingSettingsDialog';
import { Box } from '@mui/material';

export type StakingPlan = {
  id: number;
  name: {
    en: string;
    zh: string;
    ko: string;
  };
  priceInUsdt: number;
  extraDomiReward?: number;
  annualRoiPercentage: number;
  stakingLimit: number;
  validatorVotePubkey?: string;
  lockupPeriodSeconds?: number;
};

const FREE_STAKING_VOTE_PUBKEY = '67hhawRYjPHfcSWM7eeqKSCA8d4Toqi3oYEomHYw6J7S';
const FREE_STAKING_LIMIT = 5;

const FREE_STAKING_PLAN: StakingPlan = {
  id: -111,
  name: {
    en: 'Starter',
    zh: '启动器',
    ko: '스타터',
  },
  priceInUsdt: 0,
  annualRoiPercentage: 22,
  stakingLimit: FREE_STAKING_LIMIT,
};

export default function ActivateStake({
  stakingBackendUrl,
  stakingMode,
  onClose,
}: {
  stakingBackendUrl: string;
  stakingMode: StakingMode;
  onClose: () => void;
}) {
  const wallet = useWallet();
  const connection = useConnection();

  const stakingService = new StakingService(wallet, connection,
    stakingBackendUrl, stakingMode);

  const { data: stakingPlans } = useQuery({
    queryKey: ['stakingPlans', stakingBackendUrl],
    queryFn: async () => {
      return await stakingService.fetchStakingPlans()
    },
  });

  const { data: validatorInfo } = useQuery({
    queryKey: [
      'assignedValidator',
      wallet.publicKey?.toBase58(),
      stakingBackendUrl,
    ],
    queryFn: async () => {
      return await stakingService.fetchAssignedValidatorInfo();
    },
    retry: true,
    retryDelay: 3000,
  });

  return (
    <>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{strings.viewStaking}</Box>
        </Box>
      </DialogTitle>
      {stakingPlans ? (
        <DialogContent>
          <DialogContentText>
          {strings.noStaking}
          </DialogContentText>
          {validatorInfo && (
            <Box mt={2}>
              <DialogContentText>
                {strings.yourCurrentStake}
              </DialogContentText>
              <TableContainer component={Paper} variant="outlined">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{strings.validator}</TableCell>
                      <TableCell align="center">{strings.stakingLimit}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{validatorInfo.votePubkey}</TableCell>
                      <TableCell align="center">{validatorInfo.stakingLimit}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </DialogContent>
      ) : (
        <DialogContent>
          <LoadingIndicator delay={0} />
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={onClose}>{strings.close}</Button>
      </DialogActions>
    </>
  );
}
