import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import strings from '../localization';

export type Exchange = {
  name: string;
  url: string;
  pairs: string[];
};

const exchanges: Exchange[] = [
  {
    name: 'MEXC Global',
    url: 'https://www.mexc.com/exchange/DOMI_USDT?_from=search_spot_trade',
    pairs: ['DOMI/USDT']
  }
];

export default function ExchangesDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle style={{ textAlign: 'center' }}>{strings.buyDomiOnExchanges}</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{strings.exchange}</TableCell>
                <TableCell>{strings.tradingPairs}</TableCell>
                <TableCell align="right">{strings.action}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exchanges.map((exchange) => (
                <TableRow key={exchange.name}>
                  <TableCell>{exchange.name}</TableCell>
                  <TableCell>{exchange.pairs.join(', ')}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      startIcon={<OpenInNewIcon />}
                      href={exchange.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {strings.trade}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {strings.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
}