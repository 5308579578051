import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MinerStakingDialog from '../staking/surfaces/MinerStakeDialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogForm from '../DialogForm';
import strings from '../../localization';
import { CartProvider } from '../staking/surfaces/CartContext';

const useStyles = makeStyles((theme) => ({
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export default function BuyAIMinerListItem({ iconSize }) {
  const classes = useStyles();
  const [showMinerDialog, setShowMinerDialog] = useState(false);
  
  const handleOpenDialog = () => {
    setShowMinerDialog(true);
  };
  
  const handleCloseDialog = () => {
    setShowMinerDialog(false);
  };
  
  return (
    <>
      <ListItem 
        button 
        onClick={handleOpenDialog}
        className={classes.listItem}
      >
        <ListItemIcon>
          <ShoppingCartIcon fontSize={iconSize} />
        </ListItemIcon>
        <ListItemText 
          primary={strings.buyAI}
          secondary={strings.buyAIMSG}
        />
      </ListItem>
      
      <DialogForm 
        open={showMinerDialog} 
        onClose={handleCloseDialog} 
        onSubmit={handleCloseDialog}
        maxWidth="lg"
        fullWidth
        fullHeight
      >
      <CartProvider>
        <MinerStakingDialog
          open={showMinerDialog}
          onClose={handleCloseDialog}
        />
      </CartProvider>
      </DialogForm>
    </>
  );
}