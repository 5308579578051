import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import {
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  LAMPORTS_PER_SOL,
  PublicKey,
  StakeActivationData,
  ParsedAccountData
} from '@solana/web3.js';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { P, match } from 'ts-pattern';
import strings from '../../../localization';
import StakingService, { StakingMode } from '../../../services/stakingService';
import { useConnection } from '../../../utils/connection';
import { useSendTransaction } from '../../../utils/notifications';
import { abbreviateAddress, formatLamports, rewardsFormat } from '../../../utils/utils';
import { useBalanceInfo, useWallet } from '../../../utils/wallet';
import StakingSettingsDialog from '../StakingSettingsDialog';
import UnstakeAlertDialog from './UnstakeAlertDialog';

const ONCHAIN_REWARD_PERCENTAGE = 1.15;
const STAKE_LOCKUP_CUSTODIAN = '11111111111111111111111111111111';

interface ErrorResponse {
  response?: {
    status: number;
  };
}

function is404Error(error: unknown): boolean {
  if (!error) return false;
  
  const errorResponse = error as ErrorResponse;
  return errorResponse.response?.status === 404;
}

export default function ActiveStakeInfo({
  stakeAccount,
  stakeAccountInfo,
  stakingBackendUrl,
  stakingMode,
}: {
  stakeAccount: PublicKey;
  stakeAccountInfo: StakeActivationData;
  stakingBackendUrl: string;
  stakingMode: StakingMode;
}) {
  console.log("Test");
  const wallet = useWallet();
  const connection = useConnection();
  const queryClient = useQueryClient();
  const stakeAccountBalance = useBalanceInfo(stakeAccount);
  const [sendTransaction, sending] = useSendTransaction();

  const [showAlert, setShowAlert] = useState(false);
  const [isLegacyStake, setIsLegacyStake] = useState(false);
  const [isBackfilling, setIsBackfilling] = useState(false);

  //const TEST_OWNER_AMOUNT = '9pCQD6YNJYaHUb9Cgm4HVbRg55AShqCNnDb5W7t2h6Q6';
  const TEST_STAKE_ACCOUNT = 'Hd9eTc21Zm4FoV3Cyj79wsp5GDsGXCJ4YBGsVuWyinW2';

  const stakingService = new StakingService(wallet, connection,
    stakingBackendUrl, stakingMode);


  const { data: stakingPlan } = useQuery({
  queryKey: ['stakingPlan', stakingBackendUrl],
  queryFn: async () => {
    return await stakingService.fetchNewStakePlan();
  },
  retry: 5,
  retryDelay: 5000,
  });

  const votePubkey = stakingPlan?.validatorVotePubkey ? 
  new PublicKey(stakingPlan.validatorVotePubkey) : null;

  // Get the detailed account info
  const { data: detailedAccountInfo } = useQuery({
    queryKey: ['detailedAccountInfo', stakeAccount.toBase58()],
    queryFn: async () => {
      try {
        const accountInfo = await connection.getParsedAccountInfo(stakeAccount);
        if (!accountInfo.value) return null;
        const data = accountInfo.value.data as ParsedAccountData;
        if (!data.parsed) return null;
  
        return {
          parsed: data.parsed,
          owner: accountInfo.value.owner.toBase58()
        };
      } catch (error) {
        console.error('Error fetching detailed account info:', error);
        return null;
      }
    },
    enabled: Boolean(stakeAccount),
  });

  const { data: inflationReward } = useQuery({
    queryKey: ['getInflationReward', stakeAccount.toBase58()],
    queryFn: async () => {
      const result = await connection.getInflationReward([stakeAccount]);
      return result?.[0];
    },
    enabled: Boolean(stakeAccountInfo),
    refetchInterval: 60 * 1000,
  });

  const { data: stakingRewards, error: stakingRewardsError } = useQuery({
    queryKey: ['stakingRewards', stakeAccount.toBase58()],
    queryFn: async () => {
      try {
        const response = await stakingService.getStakingRewards(stakeAccount);
        console.log('getStakingRewards response:', response);
        return response;
      } catch (error) {
        console.error('getStakingRewards failed:', error);
        throw error;
      }
    },
    enabled: Boolean(stakeAccountInfo) && !isLegacyStake,
    refetchInterval: 60 * 1000,
    retry: false,
  });

  const [backfillAttempts, setBackfillAttempts] = useState(0);
  const MAX_BACKFILL_ATTEMPTS = 3;

  // Check if this is a legacy stake (no lockup or expired) and check if account needs to be backfilled
  useEffect(() => {
    const attemptBackfill = async () => {
      // Legacy Check
      if (!detailedAccountInfo) return;
  
      const lockup = detailedAccountInfo.parsed.info.meta?.lockup;
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const isLockupExpired = lockup && 
                             lockup.unixTimestamp < currentTimestamp &&
                             lockup.epoch === 0;
    
      // No lockup/expired lockup is considered legacy
      const hasNoLockup = !lockup || 
                         Object.keys(lockup).length === 0 ||
                         isLockupExpired;
      
    const isActive = detailedAccountInfo.parsed.info.stake?.delegation && 
                    stakeAccountInfo.state === 'active';
  
    const isLegacy = hasNoLockup && isActive;
      
     /* console.log('Legacy detection:', {
        hasNoLockup,
        isLockupExpired,
        currentTimestamp,
        lockupTimestamp: lockup?.unixTimestamp,
        isActive,
        isLegacy,
        lockup: detailedAccountInfo.parsed.info.meta?.lockup,
        state: stakeAccountInfo.state
      }); */
    
      setIsLegacyStake(isLegacy);

      if (isLegacy) {
        console.log('Skipping backfill for legacy stake');
        return;
      }

      // Backfill Check
      if (backfillAttempts >= MAX_BACKFILL_ATTEMPTS) {
        console.log('Maximum backfill attempts reached');
        return;
      }
      if (isBackfilling || !wallet.publicKey || !detailedAccountInfo) return;
  
      const isDelegated = Boolean(detailedAccountInfo.parsed.info.stake?.delegation);

      const has404Error = is404Error(stakingRewardsError) || stakingRewards === undefined;

       /*console.log('Backfill check:', {
        isBackfilling,
        hasWallet: Boolean(wallet.publicKey),
        hasDetailedInfo: Boolean(detailedAccountInfo),
        is404: has404Error,
        hasNoResponse: stakingRewards === undefined,
        stakingRewardsError,
        isDelegated,
        stakeInfo: detailedAccountInfo.parsed.info.stake,
        error: stakingRewardsError,
        stakeAccount: stakeAccount.toBase58(),
        ownerAddress: wallet.publicKey.toBase58(),
        attemptCount: backfillAttempts,
        isLegacyStake
      }); */
  
      if (has404Error && isDelegated) {
        try {
          console.log(`Starting backfill attempt ${backfillAttempts + 1} of ${MAX_BACKFILL_ATTEMPTS}`);
          setIsBackfilling(true);
          const response = await fetch(`${stakingBackendUrl}/v1/new-stake/backfill`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              ownerAddress: wallet.publicKey.toBase58(),
              stakeAccountAddress: stakeAccount.toBase58(),
            })
          });
      
          console.log('Backfill response:', {
            status: response.status,
            statusText: response.statusText
          });
      
          const responseData = await response.json();
          console.log('Backfill response data:', responseData);
      
          if (!response.ok) {
            throw new Error(`Backfill failed: ${responseData.error?.message || 'Unknown error'}`);
          }
      
          console.log('Backfill successful, invalidating queries');
          queryClient.invalidateQueries(['stakingRewards', stakeAccount.toBase58()]);
        } catch (error) {
          console.error('Backfill error:', {
            message: error instanceof Error ? error.message : 'Unknown error',
            attemptNumber: backfillAttempts + 1
          });
          setBackfillAttempts(prev => prev + 1);
        } finally {
          setIsBackfilling(false);
        }
      }
    };
  
    attemptBackfill();
  }, [
    detailedAccountInfo,
    stakeAccountInfo,
    stakingRewardsError, 
    detailedAccountInfo, 
    wallet.publicKey, 
    isBackfilling, 
    backfillAttempts,
    isLegacyStake 
  ]);

  const hasValidLockupNotDelegated = detailedAccountInfo?.parsed.info.meta?.lockup && 
                                  !detailedAccountInfo?.parsed.info.stake;

  const handleLegacyUnstake = () => {
    sendTransaction(stakingService.deactivateStake(stakeAccount), {
      onSuccess: refreshStakeAccountInfo,
    });
  };

  const deregisterStake = async () => {
    try {
      await stakingService.deregisterStake(stakeAccount);
    } catch (error) {
      console.warn(`Failed to delete active stake info: `, error);
    } finally {
      refreshStakeAccountInfo();
    }
  };

  const claimPartialStakeReward = async () => {
    try {
      // TODO: Display it
      const result = await stakingService.claimRewards(stakeAccount);
    } catch (error) {
      console.warn(`Failed to claim partial stake rewards: `, error);
    } finally {
      refreshStakeAccountInfo();
    }
  };

  const refreshStakeAccountInfo = () => {
    queryClient.refetchQueries({
      queryKey: ['stakeAccountInfo', stakeAccount.toBase58()],
    });
    queryClient.refetchQueries({
      queryKey: ['stakingRewards', stakeAccount.toBase58()],
    });
  };

  const handleOnPressUnstake = () => {
    sendTransaction(stakingService.deactivateStake(stakeAccount), {
      onSuccess: refreshStakeAccountInfo,
    });
  };

  const handleOnPressWithdraw = () => {
    sendTransaction(stakingService.withdrawStake(stakeAccount), {
      onSuccess: () => {
        deregisterStake();
        refreshStakeAccountInfo();
      },
    });
  };

  const handleDelegate = () => {
    if (!votePubkey) return;
    
    sendTransaction(stakingService._delegateStake(stakeAccount, votePubkey), {
      onSuccess: refreshStakeAccountInfo,
    });
  };

  const {
    // totalUnlockedForWithdraw,
    availableForWithdraw = 0,
    totalExpectedReward = 0,
    initialStakeAmount = 0,
    withdrawnAmount = 0,
    minAllowedWithdrawAmount = 0,
    stakeStartedAt = null,
    stakingPeriodSeconds = 0,
  } = stakingRewards || {};

  const totalBalanceExpected = Math.floor(initialStakeAmount * ONCHAIN_REWARD_PERCENTAGE) +
    totalExpectedReward;

  const claimStakeRewardsEnabled = stakingRewards && availableForWithdraw >= minAllowedWithdrawAmount;
  let stakingPeriodUnlocked = false;

  if (stakeStartedAt && stakingPeriodSeconds) {
    const startedDate = Date.parse(stakeStartedAt)

    if (startedDate) {
      const endedDate = startedDate + stakingPeriodSeconds * 1000;
      if (Date.now() >= endedDate) {
        stakingPeriodUnlocked = true;
      }
    }
  }

  const unstakeEnabled = stakingPeriodUnlocked && !sending;

  return (
    <>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{strings.yourStake}</Box>
          <Box>
            <StakingSettingsDialog />
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
      {hasValidLockupNotDelegated && (
            <DialogContentText 
              style={{ 
                color: '#2196f3',
                marginBottom: 16 
              }}
            >
              This stake account has a valid lockup period but is not currently delegated. You can delegate your stake to start earning rewards.
            </DialogContentText>
          )}
        {isLegacyStake && (
            <DialogContentText 
              style={{ 
                color: '#ed6c02',
                marginBottom: 16 
              }}
            >
              This appears to be a legacy stake account without lockup or an expired lockup and is not earning additonal rewards. You can unstake your funds directly and restake.
            </DialogContentText>
          )}
        <DialogContentText>
          { // @ts-ignore
            match(stakeAccountInfo)
            .with(
              { state: 'activating' },
              () => strings.stakingActivatingDescription,
            )
            .otherwise(() => '')
          }
        </DialogContentText>

        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableBody>
              {[
                [strings.stakeAccount, abbreviateAddress(stakeAccount)],
                [
                  strings.status,
                  // @ts-ignore
                  match(stakeAccountInfo.state)
                    .with('activating', () => strings.activating)
                    .with('active', () => strings.active)
                    .with('deactivating', () => strings.deactivating)
                    .with('inactive', () => strings.inactive)
                    .exhaustive(),
                ],
                [
                  strings.balance,
                  `${formatLamports(
                    inflationReward?.postBalance ?? stakeAccountBalance?.amount,
                  )} DOMI`,
                ],
                [
                  strings.activeStake,
                  `${formatLamports(stakeAccountInfo?.active)} DOMI`,
                ],
                [
                  strings.inactiveStake,
                  `${formatLamports(stakeAccountInfo?.inactive)} DOMI`,
                ],
                [
                  strings.availableForWithdraw,
                  `${rewardsFormat.format(availableForWithdraw / LAMPORTS_PER_SOL)} DOMI`,
                  true,
                ],
                [
                  strings.totalRewardWithdrawn,
                  `${rewardsFormat.format(withdrawnAmount / LAMPORTS_PER_SOL)} DOMI`,
                ],
                [
                  strings.totalBalanceExpected,
                  `${rewardsFormat.format(totalBalanceExpected / LAMPORTS_PER_SOL)} DOMI`,
                ],
              ].map(([name, value, claimButton = false]) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {name}
                  </TableCell>
                  <TableCell align="right">
                    <>
                      <b>{value}</b>
                      {claimButton ? (
                        <Button color="primary"
                                disabled={!claimStakeRewardsEnabled}
                                onClick={() => claimPartialStakeReward()}
                        >
                          {strings.claim}
                        </Button>
                      ) : null}
                    </>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        {hasValidLockupNotDelegated && (
            <Button
              color="primary"
              onClick={handleDelegate}
              disabled={sending || !votePubkey}
            >
              Delegate Stake
            </Button>
          )}
        {isLegacyStake && stakeAccountInfo.state === 'active' ? (
          <Button
            color="primary"
            onClick={handleLegacyUnstake}
            disabled={sending}
          >
            Unstake Legacy Account
          </Button>
        ) : (
          match(stakeAccountInfo)
          .with(
            // @ts-ignore
            { state: P.union('activating', 'active') },
            () => (
              <Button
                color="primary"
                onClick={() => setShowAlert(true)}
                disabled={!unstakeEnabled || sending}
              >
                {strings.unstake}
              </Button>
            )
          )
          .with(
            // @ts-ignore
            { state: P.union('deactivating', 'inactive').select() },
            (state: 'deactivating' | 'inactive') => (
              <Button
                color="primary"
                onClick={handleOnPressWithdraw}
                disabled={state === 'deactivating' || sending}
              >
                {strings.withdrawStake}
              </Button>
            )
          )
          .otherwise(() => null)
      )}
    </DialogActions>

      <UnstakeAlertDialog open={showAlert} onConfirm={() => {
        handleOnPressUnstake();
        setShowAlert(false);
      }} onClose={() => setShowAlert(false)} />
    </>
  );
}
