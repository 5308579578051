import React, { useState, useEffect } from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Box,
  Grid,
  Paper,
  Divider,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Alert
} from '@mui/material';
import { PublicKey, Transaction } from '@solana/web3.js';
import { useSnackbar } from 'notistack';
import strings from '../../../localization';
import { useConnection } from '../../../utils/connection';
import { Decimal } from 'decimal.js';
import { useWallet } from '../../../utils/wallet';
import { transferTokens, signAndSendTransaction } from '../../../utils/tokens';
import { USDT_PROGRAM_ID } from '../../../utils/tokens/instructions';
import { useTokenAccounts } from '../../../utils/tokens/hooks';
import LoadingIndicator from '../../LoadingIndicator';
import { AIRDROP_BACKEND_URL } from '../../../utils/env-variables';
import { FormControl, FormLabel, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { findAssociatedTokenAddress } from '../../../utils/tokens';
import UsdtDepositDialog from '../../bridge/usdt/UsdtDepositDialog';
import { useCart, CartSummary } from './CartContext';

interface OrderData {
  items: {
    minerId: number;
    minerName: string;
    price: number;
    quantity: number;
    subtotal: number;
  }[];
  totalPrice: number;
  customerInfo: {
    fullName: string;
    email: string;
    phoneNumber: string;
    address: string;
    city: string;
    country: string;
    postalCode: string;
    notes?: string;
  };
  timestamp: string;
  signature: string;
  tokenAccounts: string[];
  apiError: boolean;
  orderId?: string;
}

// USDT receiver address
const MASTER_WALLET_ADDRESS = "DWXcudFyubtXZboMtiz58hDCRSwAvhi4MTpUe1svxmR8";

export default function MinerOrderPage({
  open,
  onClose,
  selectedMinerId,
}) {
  const wallet = useWallet();
  const connection = useConnection();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { data: tokenAccounts, isLoading: loadingTokenAccounts } = useTokenAccounts(USDT_PROGRAM_ID);
  const { cartItems, getTotalPrice, clearCart } = useCart();
  
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    address: '',
    city: '',
    country: '',
    postalCode: '',
    notes: '',
    vendor: 'x3link'
  });
  
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transactionComplete, setTransactionComplete] = useState(false);
  const [transactionHash, setTransactionHash] = useState('');
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [usdtDepositDialogOpen, setUsdtDepositDialogOpen] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [pendingOrderData, setPendingOrderData] = useState<OrderData | null>(null);
  const [isRetrying, setIsRetrying] = useState(false);
  const [confirmedOrderItems, setConfirmedOrderItems] = useState([]);
  const [confirmedTotalPrice, setConfirmedTotalPrice] = useState(0);

  const storedOrders = localStorage.getItem('minerOrders');
  const parsedOrders = storedOrders ? JSON.parse(storedOrders) : [];
  const orderIdFromStorage = parsedOrders.find(
    (order: OrderData) => order.signature === transactionHash
  )?.orderId;
  
  const selectedMiner = MINER_TYPES.find(miner => miner.id === selectedMinerId) || MINER_TYPES[0];

  // Total price from cart
  const totalPrice = getTotalPrice();
  
  useEffect(() => {
    if (tokenAccounts) {
      const totalAmount = tokenAccounts.reduce((acc, curr) => {
        const tokenAmount = curr.account.data.parsed.info.tokenAmount;
        return acc + tokenAmount.uiAmount;
      }, 0);
      
      setUsdtBalance(totalAmount);
    }
  }, [tokenAccounts]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const isFormValid = () => {
    const requiredFields = ['fullName', 'email', 'phoneNumber', 'address', 'city', 'country', 'postalCode', 'vendor'];
    return requiredFields.every(field => formData[field].trim() !== '');
  };
  
  const hasEnoughBalance = () => {
    return usdtBalance >= selectedMiner.price;
  };

  const handleOpenUsdtDeposit = () => {
    setUsdtDepositDialogOpen(true);
  };

  const handleRetryApiSubmission = async () => {
    if (!pendingOrderData) {
      enqueueSnackbar('No pending order data to submit', { variant: 'error' });
      return;
    }
    
    const success = await submitOrderToApi(pendingOrderData);
    if (success) {
      clearCart();
    }
  };

  const submitOrderToApi = async (orderData) => {
    setIsRetrying(true);
    let apiSuccess = false;
    let orderId = null;
    
    try {
      const hasMultipleItems = orderData.items.length > 1 || orderData.items.some(item => item.quantity > 1);
    
      // Use bulk order endpoint 
      const bulkApiResponse = await fetch('https://airdrop2.domichain.io/v1/miners/bulk-orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          walletAddress: wallet.publicKey.toString(),
          items: orderData.items.map(item => ({
            minerId: item.minerId,
            minerName: item.minerName, 
            price: item.price,
            vendor: orderData.customerInfo.vendor,
            quantity: item.quantity
          })),
          shippingInfo: {
            fullName: orderData.customerInfo.fullName,
            email: orderData.customerInfo.email,
            phoneNumber: orderData.customerInfo.phoneNumber,
            address: orderData.customerInfo.address,
            city: orderData.customerInfo.city,
            country: orderData.customerInfo.country,
            postalCode: orderData.customerInfo.postalCode,
            notes: orderData.customerInfo.notes || '',
          },
          transactionSignature: orderData.signature,
        }),
      });

      if (bulkApiResponse.ok) {
        const responseData = await bulkApiResponse.json();
        console.log('Saved bulk order to backend:', responseData);
        apiSuccess = true;
        
        // Save the order ID returned from the API
        if (responseData.data && responseData.data.orderId) {
          orderId = responseData.data.orderId;
          console.log("Retrieved orderId:", orderId);
        }
      } else {
        console.error('Failed to save bulk order to backend:', await bulkApiResponse.text());
        apiSuccess = false;
      }
      
      if (apiSuccess) {
        // API submission was successful
        setApiError(false);
        setPendingOrderData(null);
        clearCart();
        enqueueSnackbar('Order details successfully sent to server!', { variant: 'success' });
      } else {
        // API call failed
        setApiError(true);
        setPendingOrderData(orderData);
        enqueueSnackbar('Order processed but server notification failed. You can retry sending the information.', 
          { variant: 'warning' }
        );
      }
    } catch (apiError) {
      console.error('Error submitting order to API:', apiError);
      setApiError(true);
      setPendingOrderData(orderData);
      apiSuccess = false;
      enqueueSnackbar('Network error when notifying server. Your order is processed but server notification failed.', 
        { variant: 'error' }
      );
    } finally {
      setIsRetrying(false);
    }

    // Store/update order in localStorage
    if (orderData.timestamp) { 
      const existingOrders = JSON.parse(localStorage.getItem('minerOrders') || '[]');
      const orderIndex = existingOrders.findIndex(order => 
        order.timestamp === orderData.timestamp && 
        order.signature === orderData.signature
      );
      
      if (orderIndex !== -1) {
        existingOrders[orderIndex].apiError = !apiSuccess;
        if (orderId) {
          existingOrders[orderIndex].orderId = orderId;
        }
        localStorage.setItem('minerOrders', JSON.stringify(existingOrders));
      }
    } else if (orderId) {  
      setPendingOrderData((prev) => ({
        ...prev, 
        orderId,
        items: prev?.items || [],
        totalPrice: prev?.totalPrice || 0,
        customerInfo: prev?.customerInfo || {
          fullName: '',
          email: '',
          phoneNumber: '',
          address: '',
          city: '',
          country: '',
          postalCode: '',
          notes: ''
        },
        timestamp: prev?.timestamp || new Date().toISOString(),
        signature: prev?.signature || '',
        tokenAccounts: prev?.tokenAccounts || [],
        apiError: prev?.apiError ?? false,
      }));
    }
    
    return { success: apiSuccess, orderId };
  };
  
  const handleSubmitOrder = async () => {
    if (!wallet || !connection || !tokenAccounts || tokenAccounts.length === 0) {
      enqueueSnackbar('Cannot access wallet or USDT accounts', { variant: 'error' });
      return;
    }
    
    const totalCartPrice = getTotalPrice();
  
    if (usdtBalance < totalCartPrice) {
      enqueueSnackbar(`Insufficient USDT balance. You need ${totalCartPrice} USDT for this purchase.`, { variant: 'error' });
      return;
    }
    
    setIsSubmitting(true);
    const notificationId = enqueueSnackbar('Processing your order...', { 
      variant: 'info', 
      persist: true 
    });
    
    try {
      const destinationPublicKey = new PublicKey(MASTER_WALLET_ADDRESS);
      
      // Sort accounts by balance to minimize the number of transactions
      const sortedAccounts = tokenAccounts
        .slice()
        .sort((a, b) => 
          Number(b.account.data.parsed.info.tokenAmount.uiAmount - a.account.data.parsed.info.tokenAmount.uiAmount)
        );
    
      let accountsToUse: { account: any; transferAmount: Decimal }[] = [];
      let remainingAmount = totalCartPrice;
    
      // Try to find one account with enough balance first
      for (const tokenAccount of sortedAccounts) {
        const accountInfo = tokenAccount.account.data.parsed.info;
        const balance = accountInfo.tokenAmount.uiAmount;
    
        if (balance >= remainingAmount) {
          // This account has enough balance to cover the entire payment
          accountsToUse.push({ 
            account: tokenAccount, 
            transferAmount: new Decimal(remainingAmount) 
          });
          remainingAmount = 0;
          break;
        }
      }
    
      // If no single account has enough, try multiple 
      if (remainingAmount > 0) {
        accountsToUse = [];
        remainingAmount = totalCartPrice;
        
        for (const tokenAccount of sortedAccounts) {
          const accountInfo = tokenAccount.account.data.parsed.info;
          const balance = accountInfo.tokenAmount.uiAmount;
          
          if (balance > 0) {
            // Determine how much to take from this account
            const amountToTake = Math.min(balance, remainingAmount);
            
            accountsToUse.push({ 
              account: tokenAccount, 
              transferAmount: new Decimal(amountToTake) 
            });
            
            remainingAmount -= amountToTake;
            
            // If we've covered the total amount
            if (remainingAmount <= 0.000001) { // Try to handle any floating point precision issues
              remainingAmount = 0;
              break;
            }
          }
        }
      }
    
      // Check if we have enough funds across all
      if (remainingAmount > 0) {
        // If we get here, we don't have enough balance across all accounts
        closeSnackbar(notificationId);
        enqueueSnackbar(`Insufficient USDT balance. You need ${totalCartPrice} USDT for this purchase.`, 
          { variant: 'error', autoHideDuration: 8000 }
        );
        return;
      }
    
      // Execute the transactions using the selected account(s)
      const signatures = await sendTransferTransactions(
        wallet,
        connection,
        accountsToUse,
        destinationPublicKey
      );
    
      console.log("Transaction signatures:", signatures);
    
      setTransactionComplete(true);
      setTransactionHash(signatures.join(','));
    
      setConfirmedOrderItems(cartItems.map(item => ({
        id: item.miner.id,
        name: item.miner.name,
        price: item.miner.price,
        quantity: item.quantity,
        subtotal: item.miner.price * item.quantity
      })));
      setConfirmedTotalPrice(totalCartPrice);
    
      // Store order in localStorage for reference
      const orderData = {
        items: cartItems.map(item => ({
          minerId: item.miner.id,
          minerName: item.miner.name,
          price: item.miner.price,
          quantity: item.quantity,
          subtotal: item.miner.price * item.quantity
        })),
        totalPrice: totalCartPrice,
        customerInfo: formData,
        timestamp: new Date().toISOString(),
        signature: signatures.join(','),
        tokenAccounts: accountsToUse.map(item => item.account.pubkey.toString()),
        apiError: false
      };

      const apiResult = await submitOrderToApi(orderData);
    
      // Set received order ID
      if (apiResult && apiResult.orderId) {
        setPendingOrderData({
          ...(pendingOrderData || {}), 
          ...orderData,
          orderId: apiResult.orderId
        });
      }
      
      const existingOrders = JSON.parse(localStorage.getItem('minerOrders') || '[]');
      existingOrders.push(orderData);
      localStorage.setItem('minerOrders', JSON.stringify(existingOrders));
      
      closeSnackbar(notificationId);
      enqueueSnackbar('Order completed successfully!', { variant: 'success' });
      
      setActiveStep(2);
    } catch (error) {
      console.error('Transaction error:', error);
      closeSnackbar(notificationId);
      enqueueSnackbar(error.message || 'Failed to process payment. Please try again.', { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              {strings.orderSummary}
            </Typography>

            <CartSummary />
            
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ mr: 2 }}>Your USDT Balance:</Typography>
                {loadingTokenAccounts ? (
                  <CircularProgress size={20} />
                ) : (
                  <Typography 
                    variant="body1" 
                    fontWeight="bold"
                    color={hasEnoughBalance() ? 'success.main' : 'error.main'}
                  >
                    {usdtBalance.toFixed(2)} USDT
                    {!hasEnoughBalance() && (
                      <Typography variant="caption" color="error.main" display="block">
                        {strings.insufficientBalance}
                      </Typography>
                    )}
                  </Typography>
                )}
              </Box>
              {!hasEnoughBalance() && !loadingTokenAccounts && (
                <Button 
                  variant="contained" 
                  color="primary"
                  onClick={handleOpenUsdtDeposit}
                  sx={{ ml: 2 }}
                >
                  Deposit USDT
                </Button>
              )}
            </Box>
          </Box>
        );
        
      case 1:
        return (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              {strings.shipping}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label={strings.name}
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  margin="normal"
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                  InputProps={{
                    style: { color: 'white' }, 
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label={strings.email}
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  margin="normal"
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                  InputProps={{
                    style: { color: 'white' }, 
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label={strings.number}
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  margin="normal"
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                  InputProps={{
                    style: { color: 'white' }, 
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label={strings.address}
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  margin="normal"
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                  InputProps={{
                    style: { color: 'white' }, 
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label={strings.city}
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  margin="normal"
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                  InputProps={{
                    style: { color: 'white' }, 
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label={strings.country}
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  margin="normal"
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                  InputProps={{
                    style: { color: 'white' }, 
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label={strings.postal}
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleInputChange}
                  margin="normal"
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                  InputProps={{
                    style: { color: 'white' }, 
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={strings.notes}
                  name="notes"
                  multiline
                  rows={4}
                  value={formData.notes}
                  onChange={handleInputChange}
                  margin="normal"
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                  InputProps={{
                    style: { color: 'white' }, 
                  }}
                />
              </Grid>
              <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" sx={{ color: 'white' }}>Select Vendor</FormLabel>
                <RadioGroup
                  row
                  name="vendor"
                  value={formData.vendor}
                  onChange={handleInputChange}
                >
                  <FormControlLabel value="x3link" control={<Radio />} label="x3link" />
                  <FormControlLabel value="Kuangri" control={<Radio />} label="Kuangri" />
                </RadioGroup>
              </FormControl>
            </Grid>
            </Grid>
          </Box>
        );
        
      case 2:
        return (
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="h6" color="primary" gutterBottom>
              {strings.orderCon}
            </Typography>
            
            {transactionComplete ? (
              <>
                {apiError && (
                  <Alert 
                    severity="warning" 
                    sx={{ mb: 3 }}
                    action={
                      <Button 
                        color="inherit" 
                        size="small" 
                        onClick={handleRetryApiSubmission}
                        disabled={isRetrying}
                      >
                        {isRetrying ? <CircularProgress size={20} /> : "RETRY"}
                      </Button>
                    }
                  >
                    {strings.apiSendFail}
                  </Alert>
                )}
                <Typography variant="body1" paragraph>
                {strings.formatString(strings.orderThx, selectedMiner.name)}
                </Typography>
                
                <Typography variant="body1" paragraph>
                {strings.formatString(strings.orderProcess, formData.email)}
                </Typography>

                {confirmedOrderItems.map((item) => (
                  <Grid container spacing={1} key={item.id} sx={{ mb: 1 }}>
                    <Grid item xs={7} textAlign="left">
                      <Typography variant="body2">
                        {item.quantity} x Node Machine {item.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} textAlign="right">
                      <Typography variant="body2">
                        {item.subtotal.toFixed(2)} USDT
                      </Typography>
                    </Grid>
                  </Grid>
                ))}

                <Divider sx={{ my: 2 }} />
                
                <Paper variant="outlined" sx={{ p: 2, mb: 3, maxWidth: 500, mx: 'auto', mt: 3 }}>
                  {(pendingOrderData?.orderId || orderIdFromStorage) && (
                    <>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        Order ID:
                      </Typography>
                      <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                        {pendingOrderData?.orderId || orderIdFromStorage}
                      </Typography>
                      <Divider sx={{ my: 2 }} />
                    </>
                  )}

                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    Transaction Hash:
                  </Typography>
                  <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                    {transactionHash}
                  </Typography>
                  
                  <Divider sx={{ my: 2 }} />
                  
                  <Grid container spacing={1}>
                    <Grid item xs={6} textAlign="left">
                      <Typography variant="body2" color="text.secondary">
                        {strings.orderDate}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <Typography variant="body2">
                        {new Date().toLocaleDateString()}
                      </Typography>
                    </Grid>
                    
                    <Grid item xs={6} textAlign="left">
                      <Typography variant="body2" color="text.secondary">
                        {strings.orderTotal}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <Typography variant="body2" fontWeight="bold">
                        {confirmedTotalPrice.toFixed(2)} USDT
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ) : (
              <LoadingIndicator />
            )}
          </Box>
        );
        
      default:
        return null;
    }
  };
  
  const steps = ['Order Summary', 'Shipping Information', 'Confirmation'];
  
  return (
    <>
      <DialogTitle>
        Order Node Machine {selectedMiner.name}
      </DialogTitle>
      
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>
                <Typography color="white">{label}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        
        {renderStepContent(activeStep)}
      </DialogContent>
      
      <DialogActions>
        {activeStep === 0 && (
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        )}
        
        {activeStep > 0 && activeStep < 2 && (
          <Button onClick={handleBack} color="primary">
            Back
          </Button>
        )}
        
        {activeStep === 0 && (
          <Button
            onClick={handleNext}
            variant="contained"
            color="primary"
            disabled={!hasEnoughBalance() || loadingTokenAccounts}
          >
            Next
          </Button>
        )}
        
        {activeStep === 1 && (
          <Button
            onClick={handleSubmitOrder}
            variant="contained"
            color="primary"
            disabled={!isFormValid() || isSubmitting || !hasEnoughBalance()}
          >
            {isSubmitting ? <CircularProgress size={24} /> : "Place Order"}
          </Button>
        )}
        
        {activeStep === 2 && (
          <Button onClick={onClose} variant="contained" color="primary">
            Done
          </Button>
        )}
      </DialogActions>
      <UsdtDepositDialog
        open={usdtDepositDialogOpen}
        onClose={() => setUsdtDepositDialogOpen(false)}
      />
    </>
  );
}

async function sendTransferTransactions(
  wallet,
  connection,
  accountsWithAmount,
  destinationPublicKey
) {
  const signatures = [];
  let transaction = new Transaction();

  for (const { account, transferAmount } of accountsWithAmount) {
    const accountInfo = account.account.data.parsed.info;
    const decimals = accountInfo.tokenAmount.decimals;

    const rawAmount = transferAmount.mul(new Decimal(10).pow(decimals));
    const roundedAmount = rawAmount.round(); 
    const amountToSend = BigInt(roundedAmount.toString());

    const transferTransaction = await transferTokens({
      connection,
      owner: wallet,
      sourcePublicKey: account.pubkey,
      destinationPublicKey,
      amount: amountToSend,
      mint: new PublicKey(accountInfo.mint),
      decimals: accountInfo.tokenAmount.decimals,
      overrideDestinationCheck: true,
      programId: USDT_PROGRAM_ID,
      memo: null,
    });

    const estimatedSize = transaction.instructions.length + transferTransaction.instructions.length;

    if (estimatedSize > 5) {
      signatures.push(
        await signAndSendTransaction(connection, transaction, wallet, [])
      );
      transaction = new Transaction();
    }

    transaction.add(transferTransaction);
  }

  if (transaction.instructions.length > 0) {
    signatures.push(
      await signAndSendTransaction(connection, transaction, wallet, [])
    );
  }

  return signatures;
}

const MINER_TYPES = [
  {
    id: 1,
    name: 'M1',
    price: 299,
    requiresStake: true,
    stakeAmount: 1000,
    lockupDays: 180,
    totalReward: 1180,
    dailyReward: 5.56,
    totalReturn: 2150,
    roi: 7.17,
    highlight: 'Entry Level',
    description: {
      en: 'Stake 1000 DOMI locked for 180 days, earn 1150 DOMI in rewards',
      zh: '质押1000个多米，180天挖1150个多米。加上1000个释放的多米，一共就是2150个多米。',
    }
  },
  {
    id: 2,
    name: 'M2',
    price: 899,
    requiresStake: false,
    lockupDays: 60,
    totalReward: 3600,
    dailyReward: 43.33,
    roi: 100,
    highlight: 'Most Popular',
    description: {
      en: 'No stake required. Earn 3600 Domi in 60 days!.',
      zh: '60天挖出3600个币，就回本了。然后每天产币3.55个，就是净赚的。',
    }
  },
  {
    id: 3,
    name: 'M3',
    price: 1499,
    requiresStake: false,
    lockupDays: 15,
    totalReward: 6000,
    dailyReward: 333.33,
    roi: 100,
    highlight: 'Best Value',
    description: {
      en: 'No stake required. Earn 6000 Domi in just 15 days!',
      zh: '15天挖出6000个币，就回本了。然后每天产币6个，就是净赚的。',
    }
  }
];

/*
            <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">{strings.miningM}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" fontWeight="bold">{selectedMiner.name}</Typography>
                </Grid>
                
                <Grid item xs={6}>
                  <Typography variant="subtitle1">{strings.price}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" fontWeight="bold">{selectedMiner.price} USDT</Typography>
                </Grid>
                
                <Grid item xs={6}>
                  <Typography variant="subtitle1">{strings.dailyReward}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedMiner.dailyReward.toFixed(2)} DOMI</Typography>
                </Grid>
                
                <Grid item xs={6}>
                  <Typography variant="subtitle1">{strings.totalReward}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedMiner.totalReward} DOMI</Typography>
                </Grid>
                
                <Grid item xs={6}>
                  <Typography variant="subtitle1">{strings.lockPeriod}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedMiner.lockupDays} days</Typography>
                </Grid>
                
                {selectedMiner.requiresStake && (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">{strings.reqStake}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">{selectedMiner.stakeAmount} DOMI</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper> */