// OrderHistory.tsx
import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  Divider,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Tooltip,
  IconButton,
  // Import NoSsr to prevent focus trap issues
  NoSsr
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import strings from '../../../localization';
import { useSnackbar } from 'notistack';
import { Alert } from '@mui/material';
import { useWallet } from '../../../utils/wallet';

// Define TypeScript interfaces for order data
interface OrderItem {
  minerId: number;
  minerName: string;
  price: number;
  quantity: number;
  subtotal: number;
}

interface CustomerInfo {
  fullName: string;
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
  country: string;
  postalCode: string;
  notes?: string;
  vendor: string;
}

interface Order {
  items: OrderItem[];
  totalPrice: number;
  customerInfo: CustomerInfo;
  timestamp: string;
  signature: string;
  tokenAccount?: string;
  apiError?: boolean; 
  pendingApiSubmission?: boolean;
  orderId?: string;
  tokenAccounts?: string[];  
}

interface OrderHistoryDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function OrderHistoryDialog({
  open,
  onClose,
}: OrderHistoryDialogProps) {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [expandedOrder, setExpandedOrder] = useState<number | null>(null);
  const [copiedHash, setCopiedHash] = useState<number | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const wallet = useWallet();
  let __DEV__ = false; // Set to true for api debugging

  useEffect(() => {
    // Reset expanded order state when opening/closing dialog to prevent potential focus trap issues
    setExpandedOrder(null);
    
    if (open) {
      // Load orders
      try {
        const storedOrders = JSON.parse(localStorage.getItem('minerOrders') || '[]');
        // Sort orders by timestamp descending (newest first)
        const sortedOrders = storedOrders.sort((a: Order, b: Order) => 
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );

        if (isMounted.current) {
          setOrders(sortedOrders);
        }
      } catch (error) {
        console.error('Error loading order history:', error);
        if (isMounted.current) {
          setOrders([]);
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    }
  }, [open]);

  const handleExpandOrder = (orderId: number) => {
    setExpandedOrder(expandedOrder === orderId ? null : orderId);
  };

  const copyToClipboard = (text: string, orderId: number) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopiedHash(orderId);
        setTimeout(() => setCopiedHash(null), 2000);
      })
      .catch(err => console.error('Failed to copy text: ', err));
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Truncate transaction hash for display
  const truncateHash = (hash: string | undefined) => {
    if (!hash) return '';
    return hash.length > 16 ? `${hash.substring(0, 8)}...${hash.substring(hash.length - 8)}` : hash;
  };

  const getOrderStatusChip = (order: Order) => {
    const orderDate = new Date(order.timestamp);
    const now = new Date();
    const daysDiff = Math.floor((now.getTime() - orderDate.getTime()) / (1000 * 60 * 60 * 24));
    
    if (daysDiff > 14) {
      return <Chip label="Delivered" color="success" size="small" />;
    } else if (daysDiff > 7) {
      return <Chip label="Shipped" color="info" size="small" />;
    } else if (daysDiff > 3) {
      return <Chip label="Processing" color="warning" size="small" />;
    } else {
      return <Chip label="Confirmed" color="primary" size="small" />;
    }
  };

  const getTotalItems = (order: Order): number => {
    if (!order || !order.items || !Array.isArray(order.items)) {
      return 0;
    }
    return order.items.reduce((total, item) => total + (item?.quantity || 0), 0);
  };

  // Track if component is mounted 
  const isMounted = useRef<boolean>(true);
  
  // Cleanup function to prevent memory leaks and focus issues
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Retry failed order (API Fail)
  const handleRetryApiSubmission = async (order: Order, orderIndex: number) => {
    const updatedOrders = [...orders];
    
    // Set this specific order to pending state
    updatedOrders[orderIndex] = {
      ...updatedOrders[orderIndex],
      pendingApiSubmission: true
    };
    
    setOrders(updatedOrders);
    
    try {
      let apiSuccess = true;
      let orderId = null;
      
      const bulkApiResponse = await fetch('https://airdrop2.domichain.io/v1/miners/bulk-orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          walletAddress: wallet.publicKey.toString(),
          items: order.items.map(item => ({
            minerId: item.minerId,
            minerName: item.minerName, 
            price: item.price,
            vendor: order.customerInfo.vendor,
            quantity: item.quantity
          })),
          shippingInfo: {
            fullName: order.customerInfo.fullName,
            email: order.customerInfo.email,
            phoneNumber: order.customerInfo.phoneNumber,
            address: order.customerInfo.address,
            city: order.customerInfo.city,
            country: order.customerInfo.country,
            postalCode: order.customerInfo.postalCode,
            notes: order.customerInfo.notes || '',
          },
          transactionSignature: order.signature,
        }),
      });

      if (bulkApiResponse.ok) {
        const responseData = await bulkApiResponse.json();
        console.log('Saved bulk order to backend:', responseData);
        apiSuccess = true;
        
        // Check if we received an order ID 
        if (responseData.data && responseData.data.orderId) {
          orderId = responseData.data.orderId;
          console.log("Retrieved orderId:", orderId);
        }
      } else {
        console.error('Failed to save bulk order to backend:', await bulkApiResponse.text());
        apiSuccess = false;
      }
      
      if (apiSuccess) {
        // API submission was successful - update order status
        updatedOrders[orderIndex] = {
          ...updatedOrders[orderIndex],
          apiError: false,
          pendingApiSubmission: false,
          ...(orderId ? { orderId } : {})
        };
        
        setOrders(updatedOrders);
        
        // Update in localStorage
        const storedOrders = JSON.parse(localStorage.getItem('minerOrders') || '[]');
        storedOrders[orderIndex].apiError = false;
        localStorage.setItem('minerOrders', JSON.stringify(storedOrders));
        
        enqueueSnackbar('Order details successfully sent to server!', { variant: 'success' });
      } else {
        // Some API calls failed
        updatedOrders[orderIndex] = {
          ...updatedOrders[orderIndex],
          apiError: true,
          pendingApiSubmission: false
        };
        
        setOrders(updatedOrders);
        enqueueSnackbar('Server notification failed. You can try again later.', { variant: 'warning' });
      }
    } catch (error) {
      console.error('Error submitting order to API:', error);
      
      // Update order status to reflect error
      const updatedOrders = [...orders];
      updatedOrders[orderIndex] = {
        ...updatedOrders[orderIndex],
        apiError: true,
        pendingApiSubmission: false
      };
      
      setOrders(updatedOrders);
      enqueueSnackbar('Network error when contacting server. Please try again later.', { variant: 'error' });
    }
  };

  //debug
  const simulateApiError = (orderIndex: number) => {
    const storedOrders = JSON.parse(localStorage.getItem('minerOrders') || '[]');
    if (storedOrders.length > orderIndex) {
      storedOrders[orderIndex].apiError = true;
      localStorage.setItem('minerOrders', JSON.stringify(storedOrders));

      const updatedOrders = [...orders];
      updatedOrders[orderIndex] = {
        ...updatedOrders[orderIndex],
        apiError: true
      };
      setOrders(updatedOrders);
      
      enqueueSnackbar('Order marked as having API error', { variant: 'info' });
    }
  };

  return (
    <NoSsr>
      <Dialog 
        open={open} 
        onClose={onClose} 
        maxWidth="md" 
        fullWidth
        // Prevent infinite focus trap loops
        disableEnforceFocus
        // Set initial focus to a stable element
        disableAutoFocus
        // Reduce focus management aggressiveness
        disableRestoreFocus
      >
      <DialogTitle>
        <Typography variant="h6">{strings.orderHistory || "Order History"}</Typography>
      </DialogTitle>
      
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="200px">
            <CircularProgress />
          </Box>
        ) : orders.length === 0 ? (
          <Box textAlign="center" py={4}>
            <Typography variant="body1" color="textSecondary">
              {strings.noOrders || "You haven't placed any orders yet."}
            </Typography>
          </Box>
        ) : (
          <Box>
            {orders.map((order, index) => (
              <Paper 
                key={index} 
                elevation={1} 
                sx={{ 
                  mb: 2, 
                  overflow: 'hidden',
                  border: '1px solid rgba(255, 255, 255, 0.12)'
                }}
              >
                {/* Order summary header */}
                <Box 
                  sx={{ 
                    p: 2, 
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.05)' }
                  }}
                  onClick={() => handleExpandOrder(index)}
                >
                  <Box>
                    <Typography variant="subtitle1" fontWeight="medium">
                      Order #{index + 1000} • {formatDate(order.timestamp)}
                    </Typography>
                    <Box display="flex" alignItems="center" mt={0.5}>
                      {getOrderStatusChip(order)}
                      <Typography variant="body2" color="textSecondary" sx={{ ml: 2 }}>
                        {getTotalItems(order)} item(s)
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ ml: 2 }}>
                        Total: {(order.totalPrice || 0).toFixed(2)} USDT
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton onClick={(e) => {
                    e.stopPropagation();
                    handleExpandOrder(index);
                  }}>
                    <ExpandMoreIcon 
                      sx={{ 
                        transform: expandedOrder === index ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s'
                      }}
                    />
                  </IconButton>
                </Box>

                {__DEV__ && (
                  <Box sx={{ p: 1, borderTop: '1px dashed gray' }}>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={(e) => {
                        e.stopPropagation();
                        const updatedOrders = [...orders];
                        updatedOrders[index] = {
                          ...updatedOrders[index],
                          apiError: !updatedOrders[index].apiError
                        };
                        setOrders(updatedOrders);
                      }}
                    >
                      Toggle API Error
                    </Button>
                  </Box>
                )}
                
                {/* Expanded order details */}
                {expandedOrder === index && (
                  <Box sx={{ p: 2, pt: 0 }}>
                    <Divider sx={{ my: 2 }} />
                    
                    {/* Items */}
                    <Typography variant="subtitle2" gutterBottom>
                      {strings.orderItems || "Order Items"}
                    </Typography>
                    <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Product</TableCell>
                            <TableCell align="right">Price</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.isArray(order.items) && order.items.map((item, itemIndex) => (
                            <TableRow key={itemIndex}>
                              <TableCell>
                                Node Machine: {item.minerName}
                              </TableCell>
                              <TableCell align="right">{item.price} USDT</TableCell>
                              <TableCell align="right">{item.quantity}</TableCell>
                              <TableCell align="right">{(item.subtotal || 0).toFixed(2)} USDT</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    
                    {/* Transaction details */}
                    <Typography variant="subtitle2" gutterBottom>
                      {strings.transactionDetails || "Transaction Details"}
                    </Typography>
                    <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
                     {(order.apiError === true) && ( 
                        <Alert 
                          severity="warning" 
                          sx={{ mb: 2 }}
                          action={
                            <Button 
                              color="inherit" 
                              size="small" 
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRetryApiSubmission(order, index);
                              }}
                              disabled={order.pendingApiSubmission}
                            >
                              {order.pendingApiSubmission ? <CircularProgress size={20} /> : "RETRY"}
                            </Button>
                          }
                        >
                          {strings.apiSendFail}
                        </Alert>
                      )}
                      <Grid container spacing={2}>
                        {order.orderId && (
                            <>
                              <Grid item xs={3}>
                                <Typography variant="body2" color="textSecondary">
                                  Order ID:
                                </Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Box display="flex" alignItems="center">
                                  <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                                    {order.orderId}
                                  </Typography>
                                </Box>
                              </Grid>
                            </>
                          )}
                        <Grid item xs={3}>
                          <Typography variant="body2" color="textSecondary">
                            Transaction Hash:
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Box display="flex" alignItems="center">
                            <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                              {truncateHash(order.signature)}
                            </Typography>
                            <Tooltip title={copiedHash === index ? "Copied!" : "Copy to clipboard"}>
                              <IconButton 
                                size="small" 
                                sx={{ ml: 1 }}
                                onClick={() => copyToClipboard(order.signature, index)}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                        
                        {order.tokenAccount && (
                          <>
                            <Grid item xs={3}>
                              <Typography variant="body2" color="textSecondary">
                                Token Account:
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                                {truncateHash(order.tokenAccount)}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Paper>

                    {__DEV__ && (
                      <Box sx={{ mt: 2, mb: 2 }}>
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() => simulateApiError(index)}
                        >
                          Simulate API Error
                        </Button>
                      </Box>
                    )}
                    
                    {/* Shipping info */}
                    {order.customerInfo && (
                      <>
                        <Typography variant="subtitle2" gutterBottom>
                          {strings.shippingInfo || "Shipping Information"}
                        </Typography>
                        <Paper variant="outlined" sx={{ p: 2 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body2" color="textSecondary">
                                {strings.name || "Name"}:
                              </Typography>
                              <Typography variant="body2">
                                {order.customerInfo.fullName}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body2" color="textSecondary">
                                {strings.email || "Email"}:
                              </Typography>
                              <Typography variant="body2">
                                {order.customerInfo.email}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body2" color="textSecondary">
                                {strings.number || "Phone Number"}:
                              </Typography>
                              <Typography variant="body2">
                                {order.customerInfo.phoneNumber}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body2" color="textSecondary">
                                {strings.country || "Country"}:
                              </Typography>
                              <Typography variant="body2">
                                {order.customerInfo.country}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body2" color="textSecondary">
                                {strings.address || "Address"}:
                              </Typography>
                              <Typography variant="body2">
                                {order.customerInfo.address}, {order.customerInfo.city}, {order.customerInfo.postalCode}
                              </Typography>
                            </Grid>
                            {order.customerInfo.notes && (
                              <Grid item xs={12}>
                                <Typography variant="body2" color="textSecondary">
                                  {strings.notes || "Notes"}:
                                </Typography>
                                <Typography variant="body2">
                                  {order.customerInfo.notes}
                                </Typography>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <Typography variant="body2" color="textSecondary">
                                Vendor:
                              </Typography>
                              <Typography variant="body2">
                                {order.customerInfo.vendor}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </>
                    )}
                  </Box>
                )}
              </Paper>
            ))}
          </Box>
        )}
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {strings.close || "Close"}
        </Button>
      </DialogActions>
    </Dialog>
    </NoSsr>
  );
}