import React, { createContext, useContext, useState, useEffect } from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grid,
  IconButton,
  Box,
  Divider,
  TextField,
  Paper,
  Badge,
  Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import strings from '../../../localization';

const CartContext = createContext();

export function CartProvider({ children }) {
  const [cartItems, setCartItems] = useState([]);
  
  useEffect(() => {
    const savedCart = localStorage.getItem('minerCart');
    if (savedCart) {
      try {
        setCartItems(JSON.parse(savedCart));
      } catch (e) {
        console.error('Error parsing cart from localStorage', e);
      }
    }
  }, []);
  
  // Save cart to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('minerCart', JSON.stringify(cartItems));
  }, [cartItems]);
  
  const addToCart = (miner, quantity = 1) => {
    setCartItems(prevItems => {
      const existingItemIndex = prevItems.findIndex(item => item.miner.id === miner.id);
      
      if (existingItemIndex >= 0) {
        // Item already exists, update quantity
        const updatedItems = [...prevItems];
        updatedItems[existingItemIndex] = {
          ...updatedItems[existingItemIndex],
          quantity: updatedItems[existingItemIndex].quantity + quantity
        };
        return updatedItems;
      } else {
        // Item doesn't exist, add new item
        return [...prevItems, { miner, quantity }];
      }
    });
  };
  
  const updateQuantity = (minerId, quantity) => {
    setCartItems(prevItems => {
      return prevItems.map(item => 
        item.miner.id === minerId 
          ? { ...item, quantity: Math.max(1, quantity) } 
          : item
      );
    });
  };
  
  const removeFromCart = (minerId) => {
    setCartItems(prevItems => prevItems.filter(item => item.miner.id !== minerId));
  };
  
  const clearCart = () => {
    setCartItems([]);
  };
  
  const getTotalItems = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };
  
  const getTotalPrice = () => {
    return cartItems.reduce((total, item) => total + (item.miner.price * item.quantity), 0);
  };
  
  const value = {
    cartItems,
    addToCart,
    updateQuantity,
    removeFromCart,
    clearCart,
    getTotalItems,
    getTotalPrice
  };
  
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

export function useCart() {
  return useContext(CartContext);
}

// CartIcon: Cart icon component with badge showing number of items
export function CartIcon({ onClick }) {
  const { getTotalItems } = useCart();
  const totalItems = getTotalItems();
  
  return (
    <Tooltip title="View Cart">
      <IconButton onClick={onClick} color="inherit">
        <Badge badgeContent={totalItems} color="error" showZero={false}>
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}

// CartDialog: Dialog to display cart contents
export function CartDialog({ open, onClose, onProceedToCheckout }) {
  const { cartItems, updateQuantity, removeFromCart, getTotalPrice, clearCart } = useCart();
  
  const handleQuantityChange = (minerId, newQuantity) => {
    updateQuantity(minerId, Math.max(1, parseInt(newQuantity) || 1));
  };
  
  const handleIncrement = (minerId, currentQuantity) => {
    updateQuantity(minerId, currentQuantity + 1);
  };
  
  const handleDecrement = (minerId, currentQuantity) => {
    if (currentQuantity > 1) {
      updateQuantity(minerId, currentQuantity - 1);
    }
  };
  
  const totalPrice = getTotalPrice();
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{strings.cart || "Shopping Cart"}</Typography>
          {cartItems.length > 0 && (
            <Button 
              variant="text" 
              color="error" 
              onClick={clearCart}
              size="small"
            >
              Clear Cart
            </Button>
          )}
        </Box>
      </DialogTitle>
      
      <DialogContent>
        {cartItems.length === 0 ? (
          <Box textAlign="center" py={4}>
            <Typography variant="body1" color="textSecondary">
              {strings.emptyCart || "Your cart is empty"}
            </Typography>
          </Box>
        ) : (
          <>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={5}>
                <Typography variant="subtitle2">Product</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">Price</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle2">Quantity</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle2">Total</Typography>
              </Grid>
            </Grid>
            
            <Divider sx={{ mb: 2 }} />
            
            {cartItems.map((item) => (
              <Paper 
                key={item.miner.id} 
                variant="outlined" 
                sx={{ mb: 2, p: 2 }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" fontWeight="bold">
                      {strings.miningMachine || "Node Machine"}: {item.miner.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.miner.description.en.split('.')[0]}.
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={3}>
                    <Typography variant="body1">
                      {item.miner.price} USDT
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={2}>
                    <Box display="flex" alignItems="center">
                      <IconButton 
                        size="small" 
                        onClick={() => handleDecrement(item.miner.id, item.quantity)}
                        disabled={item.quantity <= 1}
                      >
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                      
                      <TextField
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(item.miner.id, e.target.value)}
                        inputProps={{ 
                          min: 1, 
                          style: { textAlign: 'center', width: '40px' }
                        }}
                        variant="outlined"
                        size="small"
                      />
                      
                      <IconButton 
                        size="small" 
                        onClick={() => handleIncrement(item.miner.id, item.quantity)}
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={1}>
                    <Typography variant="body1" fontWeight="bold">
                      {(item.miner.price * item.quantity).toFixed(2)} USDT
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={1}>
                    <IconButton 
                      color="error" 
                      size="small" 
                      onClick={() => removeFromCart(item.miner.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            ))}
            
            <Divider sx={{ mt: 2, mb: 2 }} />
            
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Typography variant="h6" sx={{ mr: 2 }}>
                Total:
              </Typography>
              <Typography variant="h6" fontWeight="bold" color="primary">
                {totalPrice.toFixed(2)} USDT
              </Typography>
            </Box>
          </>
        )}
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {strings.continueShopping || "Continue Shopping"}
        </Button>
        
        {cartItems.length > 0 && (
          <Button 
            variant="contained" 
            color="primary" 
            onClick={onProceedToCheckout}
          >
            {strings.proceedToCheckout || "Proceed to Checkout"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

// CartSummary: Component to show cart summary during checkout
export function CartSummary() {
  const { cartItems, getTotalPrice } = useCart();
  const totalPrice = getTotalPrice();
  
  return (
    <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        {strings.orderSummary || "Order Summary"}
      </Typography>
      
      <Divider sx={{ mb: 2 }} />
      
      {cartItems.map((item) => (
        <Box key={item.miner.id} sx={{ mb: 2 }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body2">
                {item.quantity} x {strings.miningMachine || "Node Machine"} {item.miner.name}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {(item.miner.price * item.quantity).toFixed(2)} USDT
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="caption" color="textSecondary" display="block">
            {strings.dailyReward || "Daily Reward"}: {(item.miner.dailyReward * item.quantity).toFixed(2)} DOMI
          </Typography>
        </Box>
      ))}
      
      <Divider sx={{ mt: 2, mb: 2 }} />
      
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="subtitle1" fontWeight="bold">
            {strings.totalPrice || "Total Price"}:
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography variant="subtitle1" fontWeight="bold" color="primary">
            {totalPrice.toFixed(2)} USDT
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}